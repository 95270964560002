import React from 'react';

import { useLocalStorage } from '../../hooks/localstorage';

const ReleaseNoteContext = React.createContext([{}, () => {}]);
// const CLIENT_VERSION = process.env.REACT_APP_VERSION;
const LATEST_RELEASE_NOTES = '1.8.5'; // We have this here because sometimes we want to force a refresh without making new release notes.

function ReleaseNoteProvider({ children }) {
  const [releaseNotesRead, setReleaseNotesRead] = useLocalStorage('releaseNotesRead', null);

  const setRead = () => {
    setReleaseNotesRead(LATEST_RELEASE_NOTES);
  };

  const isCurrentReleaseNotesRead = () => releaseNotesRead && releaseNotesRead === LATEST_RELEASE_NOTES;

  return (
    <ReleaseNoteContext.Provider value={{ setRead, isCurrentReleaseNotesRead }}>
      {children}
    </ReleaseNoteContext.Provider>
  );
}

export default ReleaseNoteContext;

export { ReleaseNoteContext, ReleaseNoteProvider };
