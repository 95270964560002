import * as moment from 'moment';
import { USER_TYPES, WEBSITE_TYPES } from '../constants/enums';
import { getTextValue, USER_TYPES as USER_TYPES_TRANSLATION, USER_TYPES_FOR_TENANT } from '../constants/translation';

const collator = new Intl.Collator('sv');

export const convertArrayToObject = (array, key) => {
  const initialValue = {};
  return array.reduce((obj, item) => ({
    ...obj,
    [item[key]]: item,
  }), initialValue);
};

export const parseDate = (input) => {
  if (!input) return '';
  const date = typeof (input) === 'string' ? moment(input) : input;
  return moment(date).format('YYYY-MM-DD');
};

export const parseDateTime = (input) => {
  if (!input) return '';
  const date = typeof (input) === 'string' ? moment(input) : input;
  return moment(date).format('YYYY-MM-DD HH:mm');
};

export const parseTime = (input) => {
  if (!input) return '';
  const date = typeof (input) === 'string' ? moment(input) : input;
  return moment(date).format('HH:mm');
};

export const parseCustomDateTime = (input, format) => {
  if (!input) return '';
  const date = typeof (input) === 'string' ? moment(input) : input;
  return moment(date).format(format);
};

export const parseUtcISODate = (input) => {
  if (!input) return '';
  const date = typeof (input) === 'string' ? moment(input) : input;
  return moment(date).utc().format('YYYYMMDD[T]HHmmss[Z]');
};

export const dateCompare = (a, b) => (a ? a.localeCompare(b) : -1);

export const maxDate = () => new Date(8640000000000000);
export const minDate = () => new Date(-8640000000000000);

export const addSelectToObject = (list) => {
  const emptyOption = { '': 'Välj' };
  return { ...emptyOption, ...list };
};

export const convertStringToInt = (str) => (str && str.length > 0 ? parseInt(str, 10) : null);

export const serializeToQueryString = (obj) => Object.entries(obj).map(([key, value]) => {
  if (!value) return '';
  return `${encodeURIComponent(key)}=${encodeURIComponent(value)}`;
}).filter((val) => val).join('&'); // Filter out null values and join with &

export const displayValueOrMissing = (value, replaceWith = '-') => value || replaceWith;

export const displayYesNo = (value, formatMessage) => {
  if (value === null) {
    return '-';
  }
  return value ? formatMessage({ id: 'enums.yes-no-choice.yes', defaultMessage: 'Ja' }) : formatMessage({ id: 'enums.yes-no-choice.no', defaultMessage: 'Nej' });
};

export const omitKeyFromObject = (key, { [key.toString()]: _, ...obj }) => obj;

export const groupBy = (items, key) => items.reduce(
  (result, item) => ({
    ...result,
    [item[key]]: [
      ...(result[item[key]] || []),
      item,
    ],
  }),
  {},
);

export const orderWorkCategories = (categories) => {
  let cat = categories.find((category) => !category.parentId);
  const catList = [];
  while (cat) {
    catList.push(cat);
    cat = categories.find((category) => category.parentId === cat.id);
  }
  return catList;
};

export const convertTruthyObjectsToArray = (obj) => Object.entries(obj).filter(([_key, value]) => !!value).map(([key]) => key);

// Example usage: pad('00000',123,true); -> '00123'
export const pad = (value, padChars, padLeft = true) => {
  if (typeof value === 'undefined') return padChars;
  if (padLeft) return (padChars + value).slice(-padChars.length);
  return (value + padChars).substring(0, padChars.length);
};

export const debounce = (func, wait, immediate) => {
  let timeout;

  return function executedFunction() {
    const context = this;
    const args = arguments;

    const later = () => {
      timeout = null;
      if (!immediate) func.apply(context, args);
    };

    const callNow = immediate && !timeout;

    clearTimeout(timeout);

    timeout = setTimeout(later, wait);

    if (callNow) func.apply(context, args);
  };
};

export const calculateStars = (val) => {
  if (val < 0.5) return 0;
  if (val < 1.5) return 1;
  if (val < 2.5) return 2;
  if (val < 3.5) return 3;
  if (val < 4.5) return 4;
  return 5;
};

export const arrayToCommaList = (array) => array.join(', ').replace(/, ([^,]*)$/, ' och $1');

export const preUserPath = (userType) => (userType === USER_TYPES.CONTRACTOR ? '/contractor/' : '');

export const getPropByString = (obj, path) => {
  if (!path) return obj;
  const properties = path.split('.');
  return getPropByString(obj[properties.shift()], properties.join('.'));
};

export const capitalizeFirstLetter = ([firstChar, ...rest]) => `${firstChar.toUpperCase()}${rest.join('')}`;

export const fullName = (user) => `${user.firstName} ${user.lastName}`;

export const getUserStatus = (user) => {
  if (user.deletedAt) return 'Inaktiv';
  if (!user.acceptedTosAt) return 'Ej registrerad';
  return 'Aktiv';
};

export const limitInputLength = (e, maxLength, allowRegex = null) => {
  // If there is a allowRegex, first remove any non allowed characters, and then limit
  const { target } = e;
  if (!target || !target.value) return;

  let { value } = target;
  let changed = false;
  if (allowRegex) {
    const match = value.match(allowRegex);
    if (match) {
      value = match.join('');
      changed = true;
    }
  }

  if (value.length > maxLength) target.value = value.slice(0, maxLength);
  else if (changed) target.value = value;
};

// Example usage: keyPressDisallow(e, /\d/); -> Only allow numbers. Important! You have to specify the allowed regexp, not the disallowed
export const keyPressDisallow = (e, allowRegex) => {
  if (!e.key.match(allowRegex)) {
    e.preventDefault();
  }
};

export const getWebsiteType = () => (process.env.REACT_APP_WEBSITE_TYPE && WEBSITE_TYPES[process.env.REACT_APP_WEBSITE_TYPE.toUpperCase()]) || WEBSITE_TYPES.STANDARD;

export const inputToLowerCase = ({ target }) => {
  if (target && target.value) target.value = target.value.toLowerCase();
};

// This function is used to translate the user type to the correct type for propcomp and contractor respectively and the tenant
// Example usage: toType(USER_TYPES.CONTRACTOR, USER_TYPES.TENANT) -> 'Slutkund'
// eslint-disable-next-line arrow-body-style
export const getUserTypeTranslation = (thisUserType, userType, formatMessage, capitalize = false) => {
  const translationObj = thisUserType === USER_TYPES.TENANT ? USER_TYPES_FOR_TENANT[userType] : USER_TYPES_TRANSLATION[userType];
  const text = getTextValue(translationObj, formatMessage);
  return capitalize ? capitalizeFirstLetter(text) : text;
};

export const timeLeftToDate = (date) => {
  const now = moment();
  const targetDate = moment(date);
  const diff = moment.duration(targetDate.diff(now));

  return diff;
};

export const getClosestAcceptedVisit = (visits, fromDate = null) => {
  // If fromDate is not set, set it to 1 hour back
  const fromDateFilter = fromDate || new Date(Date.now() - 1000 * 60 * 60);
  const visitsCopy = [...visits];
  return visitsCopy
    .filter((v) => v.acceptedAt && !v.deletedAt && new Date(v.date).getTime() > fromDateFilter)
    .sort((a, b) => new Date(a.date).getTime() - new Date(b.date).getTime())[0];
};

// array move
export function arrayMoveMutable(array, fromIndex, toIndex) {
  const startIndex = fromIndex < 0 ? array.length + fromIndex : fromIndex;

  if (startIndex >= 0 && startIndex < array.length) {
    const endIndex = toIndex < 0 ? array.length + toIndex : toIndex;

    const [item] = array.splice(fromIndex, 1);
    array.splice(endIndex, 0, item);
  }
}

export function arrayMove(array, fromIndex, toIndex) {
  const newArray = [...array];
  arrayMoveMutable(newArray, fromIndex, toIndex);

  return newArray;
}

export const pick = (obj, fields) => fields.reduce((acc, f) => ({ ...acc, [f]: obj[f] }), {});

export const roundToDecimalPlaces = (num, decimalPlaces) => Number(num.toFixed(decimalPlaces));

export const deepMerge = (target, source) => {
  for (const key in source) {
    if (source[key] instanceof Object && key in target) {
      Object.assign(source[key], deepMerge(target[key], source[key]));
    }
  }
  Object.assign(target || {}, source);
  return target;
};

export const shallowCopy = (obj) => Object.assign(Object.create(Object.getPrototypeOf(obj)), obj);
